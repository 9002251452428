<template>
  <v-container fluid>
    <section id="loading" v-if="isLoading">
      <v-row class="mt-1">
        <v-col cols="6">
          <v-skeleton-loader
            type="heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-skeleton-loader
              type="heading"
              class="pa-4"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="
              list-item-three-line,
              list-item-three-line,
              list-item-three-line,
              list-item-three-line,
              list-item-three-line
              "
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section id="error" v-if="!isLoading && isError">
      <v-container style="height:80vh" class="white d-flex align-center justify-center">
        <div class="text-center">
          <h1 class="mb-4">Terjadi Kesalahan</h1>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="fetchData"
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>
                  mdi-reload
                </v-icon>
              </v-btn>
            </template>
            <span>Reload Halaman</span>
          </v-tooltip>
        </div>
      </v-container>
    </section>
  </v-container>
</template>
<script>
import { pageNameByDefworkflow, servicesByDefworkflow } from '../helper/workflowCode';

export default {
  data() {
    return {
      isError: false,
      isLoading: false,
    };
  },
  mounted() {
    const { params } = this.$route;
    if (params) {
      this.fetchData();
    } else {
      this.$router.replace('/main');
    }
  },
  methods: {
    pageNameByDefworkflow,
    servicesByDefworkflow,
    async fetchData() {
      const { params } = this.$route;
      const { id, defWorkflowId, page } = params;
      try {
        this.isLoading = true;
        const result = await this.$_services[this.servicesByDefworkflow(+defWorkflowId).service][this.servicesByDefworkflow(+defWorkflowId).function](+id);
        const query = {};
        if (this.$route.query) {
          const { tab, approvalAction } = this.$route.query;
          query.tab = tab;
          if (approvalAction) query.approvalAction = approvalAction;
        }
        const _page = page === 'inProcess' ? 'process' : page;
        this.$router.replace({
          name: this.pageNameByDefworkflow(+defWorkflowId, _page),
          params: {
            id,
            defWorkflowId,
            data: result.data,
          },
          query,
        });
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.go(-1);
        }
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
